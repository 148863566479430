import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Legacy PHP To Top Notch Code",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T17:32:51.000Z",
  "url": "/2019/05/legacy-php-to-top-notch-code/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Tech Stack:`}</strong>{` PHP, Laravel, Slim, Composer, docker While joining an old experienced team with a great product that exists for several years for creating some new features. I noticed the codebase problems and time waste it caused the rest of team and myself. We initially all worked locally and synced the code to a cloud server to test our changes. This process was slow and had a lot of problems for all of us. My first step was to create a replica of the stack in the docker and share the working local environment with the rest of team for feedback. That passed through great. `}</p>
    <h4>{`The Legacy Code The product was amazing and it supported great features. But for any new feature, we needed to do a lot of changes and surely didn`}{`’`}{`t care from DRY principle (don`}{`’`}{`t repeat yourself). The code had four different entry-points to the app and each entry point had each own routes. Authentication was reimplemented in different ways and were duplicated on each route code.`}</h4>
    <h4>{`What we did:`}</h4>
    <ul>
      <li parentName="ul">{`Started using Laravel DI container`}</li>
      <li parentName="ul">{`Added middlewares for:`}
        <ul parentName="li">
          <li parentName="ul">{`authentication`}</li>
          <li parentName="ul">{`data validation`}</li>
          <li parentName="ul">{`env setup, current account, current user, etc`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Added the use of Controllers instead of anonymous functions`}</li>
      <li parentName="ul">{`Used Created common function for handling responses and errors`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      